/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import 'web-style-guide/css/drilldown.less';
import DraggableModalDialog from 'containers/DraggableModalDialog';
import Icons from 'templates/Icons';


const DrillDownPopUp = (props) => {
    var bgcolor=props.backColor=='#ffffff' ? '#000000' : props.backColor;

  return (
    <Modal 
      dialogComponentClass={DraggableModalDialog}  
      id="modal" 
      className="drilldownPopup" 
      show={props.show} 
      dialogClassName="xlgModal"
      enforceFocus={false} 
      backdrop='static'
      backdropClassName='backdropTransparent'
      keyboard
      onHide={props.onHide}
    >
        <Modal.Header id="modal-header" style={{background: bgcolor + "bf"}}>
            <Modal.Title>
                {props.title}
            </Modal.Title>
                <button className="close drillBtns" type="button" onClick={props.onHide}>
                    <i className={Icons.close}></i>
                </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} sm={11} className={`block-center modal-body-wrapper ${props.autoHeight ? 'autoHeight' : ''}`}>
              {props.children}
            </Col>
          </Row>
        </Modal.Body>
    </Modal>
  );
}

export default DrillDownPopUp;
